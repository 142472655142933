
import { defineComponent, ref, watch, onMounted, reactive } from 'vue';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import { useStore } from 'vuex';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { globalDate, dateInitial30Days, generateDate30Days, formateDate } from '@/views/listaDeOS/composables/dateInitial'
import { Actions } from '@/store/enums/StoreEnums';
import { getPacotes } from '@/services/ListaOS'
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from 'moment';
import useAlert from "@/composables/Alert";
import { consultoresList } from "@/services/CrudService";

export default defineComponent({
  name: "filtro-grupos",
  components: {
    FilterBase,
    ElConfigProvider,
  },
  setup(props, { emit }) {

    const store = useStore();
    const { showTimeAlert } = useAlert();
    const disabledClick = ref(false);
    let valorInicialCodConcessionaria;
    if (window.localStorage.getItem("id_usuario")) {
      valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
    } else {
      valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
    }
    const codConcessionariaSelected: any = ref(valorInicialCodConcessionaria ?? "");
    const emitter = useEmitter();
    const activeModal = ref(false);
    const closeModal = ref(false);
    const label = ref("faça filtros e aprimore sua busca");
    const showClose = ref(false)
    const optionsConcessionaria: any = ref([]);
    const datePicker = ref()
    const lastEmitIsNull = ref(false);
    const showGoToBack = ref(false);
    const optionsPacote = ref()
    const loadingInput = ref(false)
    const optionsConsultor = ref()
    const filterOs = reactive({
      pacote: null,
      date: '',
      status: null,
      codConsultor: null,
      placa: '',
    })

    onMounted(async () => {
      if (store.getters.concessionariasList.length > 0) {
        trataConcessionarias();
      }
      generateDate30Days()
      datePicker.value = dateInitial30Days.value

    });

    store.watch(() => store.getters.concessionariasList, () => {
      trataConcessionarias();
    }, { deep: true });

    const getAllConsultores = async () => {

      try {
        const consultores = await consultoresList(codConcessionariaSelected.value)
        return consultores
      } catch (err) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      }
    }

    async function trataConcessionarias() {
      codConcessionariaSelected.value = window.localStorage.getItem("filtroConcessionaria")
      const concessionarias = store.getters.concessionariasList;
      optionsConcessionaria.value = concessionarias.map(concessionaria => {
        return {
          text: concessionaria.nomeFantasia,
          value: concessionaria.codConcessionaria,

          disabled: !concessionaria.temOficinaDigital,
        }
      }).sort((concessionariaA, concessionariaB) => {
        if (!concessionariaA.disabled && concessionariaB.disabled) {
          return -1;
        }
        if (!concessionariaB.disabled && concessionariaA.disabled) {
          return 1;
        }
        if (concessionariaA.disabled && !concessionariaA.disabled) {
          if (concessionariaA.text < concessionariaB.text) {
            return -1;
          }
          if (concessionariaA.text > concessionariaB.text) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    }

    async function emiteMudanca() {
      if (window.localStorage.getItem("id_usuario")) {
        window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      } else {
        window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      }

      store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);

      globalDate.value = formateDate(datePicker.value[0], datePicker.value[1])
      filterOs.date = globalDate.value
      emitter.emit('filter-OS', { filterOs, codConcesionaria: codConcessionariaSelected.value })

    }

    watch(() => [codConcessionariaSelected.value, store.getters.concessionariasList, codConcessionariaSelected], async () => {
      loadingInput.value = true
      optionsPacote.value = await getAllpacotes()
      optionsConsultor.value = await getAllConsultores()
      loadingInput.value = false
    })

    async function getAllpacotes() {

      try {
        const namePacotes = await getPacotes(codConcessionariaSelected.value)
        return namePacotes
      } catch (err) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      }
    }

    function verificaIntervalo(data) {
      var diff = moment(data[1]).diff(moment(data[0]), "months");
      if (diff <= 24) {
        const endData = moment(datePicker.value[1]);
        const sameMounthAndYear = moment().isSame(endData, 'month') && moment().isSame(endData, 'year');
        const endDataTratada = sameMounthAndYear ? moment().endOf("day") : endData.endOf("month").endOf("day");
        const dataTratada = [moment(datePicker.value[0]).startOf("day").toDate(), endDataTratada];
        emiteMudanca()
        closeModal.value = !closeModal.value;
      } else {
        Swal.fire({
          title: "Período inválido!",
          html: `<h4>Não é possível filtrar um período superior a 24 meses (2 anos)!</h4>`,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
          },
        });
      }
    };



    return {
      activeModal,
      closeModal,
      showClose,
      ptBr,
      emitter,
      emiteMudanca,
      optionsConcessionaria,
      codConcessionariaSelected,
      showGoToBack,
      label,
      disabledClick,
      datePicker,
      filterOs,
      optionsPacote,
      lastEmitIsNull,
      loadingInput,
      verificaIntervalo,
      optionsConsultor
    }
  }
});

